import Radio from "../components/Radio";
import Rate from "../components/Rate";
import CheckBox from "../components/CheckBox";
import splash from "../assets/images/splash.png";
import logo from "../assets/images/logo.png";
import user from "../assets/images/user.png";
import { FaWhatsapp } from "react-icons/fa";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TextArea from "../components/TextArea";
import Swal from "sweetalert2";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../store/store";
import { setQuestionIndex } from "../components/redux/form-slice";
import $ from "jquery";
import Share from "../components/Share";

function Survey() {
  const { t } = useTranslation("global");

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const { questionIndex } = useAppSelector((state) => state.form);
  const { answers, name, email, phone } = useAppSelector((state) => state.form);

  const handleChangeLanguage = () => {
    if (i18next.language == "ar") {
      i18next.changeLanguage("en");
      $(".en-class").removeClass("ar-class");
      setLoading(true);
      fetchData();
    } else {
      i18next.changeLanguage("ar");
      $(".en-class").addClass("ar-class");
      setLoading(true);
      fetchData();
    }
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function fetchData() {
    axios
      .post("https://vidro.manialab.sa/api/get-section", {
        lang: i18next.language,
      })
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.questions.length == 0) {
            Swal.fire({
              title: "Something went!",
              text: "Requested survey seems empty",
              icon: "error",
              confirmButtonText: "Okay",
            }).then(() => {
              navigate("/");
            });
          } else {
            setQuestions(response.data.data.questions);

            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }
        } else {
          Swal.fire({
            title: "Something went!",
            text: "Requested survey seems empty",
            icon: "error",
            confirmButtonText: "Okay",
          }).then(() => {
            navigate("/");
          });
        }
      });
  }

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem("lang"));

    if (localStorage.getItem("lang") == "en") {
      $(".en-class").removeClass("ar-class");
    } else {
      $(".en-class").addClass("ar-class");
    }
    fetchData();
  }, []);

  async function next() {
    if (questionIndex == questions.length) {
      $(`.answer-group-${questionIndex - 1}`).addClass("disabled");
      $(`.send-button-${questionIndex - 1}`).addClass("remove");

      $(".question-group-100").addClass("animate");

      setTimeout(() => {
        axios
          .post("https://vidro.manialab.sa/api/submit-answers", {
            customer: {
              name: name,
              email: email,
              phone: phone,
            },
            answers: answers,
          })
          .then(() => {
            navigate("/thankyou");
          });
      }, 10000);
    } else {
      $("html, body").animate(
        {
          scrollTop: $(`.question-group-${questionIndex}`).offset().top - 200,
        },
        500
      );

      $(`.question-header-${questionIndex}`).addClass("animate");
      $(`.question-group-${questionIndex}`).addClass("animate");
      $(`.answer-group-${questionIndex}`).addClass("animate");
      $(`.send-button-${questionIndex - 1}`).addClass("remove");
      $(`.answer-group-${questionIndex - 1}`).addClass("disabled");

      dispatch(setQuestionIndex(questionIndex + 1));
    }
  }

  if (loading) {
    return (
      <div className="loading">
        <img src={logo} alt="logo" />
      </div>
    );
  } else {
    return (
      <>
        <div className="border-top">
          <span
            className="lang"
            onClick={() => {
              handleChangeLanguage();
            }}
          >
            {i18next.language}
          </span>
        </div>

        <div
          className="survey-page"
          style={i18next.language == "ar" ? { direction: "rtl" } : {}}
        >
          <img src={logo} alt="logo" className="logo" />

          {questions.map((question, index) => {
            return (
              <div key={question.id}>
                {question.slug == "email" ? (
                  <div className={`question-header question-header-${index}`}>
                    <img src={logo} alt="logo" className="logo" />
                    <div className="question">
                      {name + ", " + question.header}
                    </div>
                  </div>
                ) : question.header != null ? (
                  <div className={`question-header question-header-${index}`}>
                    <img src={logo} alt="logo" className="logo" />
                    <div className="question">{question.header}</div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className={`question-group question-group-${index}`}>
                  <img src={logo} alt="logo" className="logo" />
                  <div className="question">{question.question}</div>
                </div>

                <div className={`answer-group answer-group-${index}`}>
                  <div className="answer">
                    {question.type === "radio" && (
                      <Radio
                        questionId={question.id}
                        questionAnswers={question.answers}
                      />
                    )}
                    {(question.type === "text" || question.type === "info") && (
                      <TextArea
                        questionId={question.id}
                        questionSlug={question.slug}
                      />
                    )}
                    {question.type === "rate" && (
                      <Rate questionId={question.id} stars={question.answers} />
                    )}
                    {question.type === "checkbox" && (
                      <CheckBox
                        questionId={question.id}
                        questionAnswers={question.answers}
                      />
                    )}
                    {question.type === "share" && (
                      <Share questionId={question.id} />
                    )}

                    <div className={`send-button send-button-${index}`}>
                      <button
                        onClick={next}
                        style={
                          i18next.language == "ar"
                            ? { marginRight: "auto", marginLeft: 0 }
                            : {}
                        }
                      >
                        {t("send")}
                      </button>
                    </div>
                  </div>
                  <img src={user} alt="logo" className="logo" />
                </div>
              </div>
            );
          })}

          <div className={"question-group question-group-100"}>
            <img src={logo} alt="logo" className="logo" />
            <div className="question">{t("formSubmitted")}</div>
          </div>

          <div className={"question-group question-group-100"}>
            <img src={logo} alt="logo" className="logo" />
            <div className="question">
              <div>{t("share")}</div>
              <div className="share-links">
                <a
                  onClick={() =>
                    window.open(
                      "whatsapp://send?image=&text=Hello%2C%20I%20had%20a%20great%20experience%20at%20VIDRO%2C%20I%20recommend%20this%20place%20to%20visit.%0AInstagram%3A%20https%3A%2F%2Fwww.instagram.com%2Fvidro.sa%2F%0ATwitter%3A%20https%3A%2F%2Ftwitter.com%2FVidro_sa%0AWhatsapp%3A%20https%3A%2F%2Fapi.whatsapp.com%2Fsend%2F%3Fphone%3D%252B966555351612"
                    )
                  }
                >
                  <FaWhatsapp className="icon" />
                </a>
              </div>
            </div>
          </div>

          <img className="splash-image" src={splash} />
          <div className="border-bottom"></div>
        </div>
      </>
    );
  }
}

export default Survey;
