import { FaStar } from "react-icons/fa";
import $ from "jquery";
import { setAnswers } from "./redux/form-slice";
import { useAppDispatch, useAppSelector } from "../store/store";

function Rate({ stars, questionId }) {
  const dispatch = useAppDispatch();
  const { answers } = useAppSelector((state) => state.form);

  function selectRate(answerId, rate, rateIndex) {
    var newAnswers = [...answers];

    var index = newAnswers.findIndex(
      (answer) => answer.question_id == questionId
    );

    if (index == -1) {
      newAnswers.push({
        question_id: questionId,
        answer_id: answerId,
        answer: rate,
      });
    } else {
      newAnswers[index] = {
        question_id: questionId,
        answer_id: answerId,
        answer: rate,
      };
    }

    dispatch(setAnswers(newAnswers));

    $(".star-fill-" + questionId).removeClass("star-active");

    var starClass = ".star-fill-" + questionId + "-";
    for (let i = 0; i <= rateIndex; i++) {
      $(starClass + i).addClass("star-active");
    }
  }

  return (
    <>
      {stars.map((star, index) => {
        return (
          <FaStar
            className={`star star-fill-${questionId}-${index} star-fill-${questionId}`}
            key={index}
            name={star.answer_value}
            onClick={() => selectRate(star.id, star.answer_value, index)}
          />
        );
      })}
    </>
  );
}

export default Rate;
