import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../store/store";
import { setAnswers, setEmail, setName, setPhone } from "./redux/form-slice";

function TextArea({ questionId, questionSlug }) {
  const { t } = useTranslation("global");

  const dispatch = useAppDispatch();
  const { answers } = useAppSelector((state) => state.form);
  const { name } = useAppSelector((state) => state.form);

  function changeAnswer(answerValue) {
    var newAnswers = [...answers];

    var index = newAnswers.findIndex(
      (answer) => answer.question_id == questionId
    );

    if (questionSlug == "name") {
      dispatch(setName(answerValue));
    } else if (questionSlug == "email") {
      dispatch(setEmail(answerValue));
    } else if (questionSlug == "phone") {
      dispatch(setPhone(answerValue));
    } else {
      if (index == -1) {
        newAnswers.push({
          question_id: questionId,
          answer_id: "",
          answer: answerValue,
        });
      } else {
        newAnswers[index] = {
          question_id: questionId,
          answer_id: "",
          answer: answerValue,
        };
      }
    }

    dispatch(setAnswers(newAnswers));
  }

  return (
    <>
      <textarea
        rows={4}
        placeholder={t("typeHere")}
        onChange={(e) => changeAnswer(e.target.value)}
      ></textarea>
    </>
  );
}

export default TextArea;
