import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questionIndex: 1,
  name: "",
  email: "",
  phone: "",
  answers: [],
};

const formSlice = createSlice({
  name: "form-slice",
  initialState,
  reducers: {
    setQuestionIndex: (state, action) => {
      state.questionIndex = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setAnswers: (state, action) => {
      state.answers = action.payload;
    },
  },
});

export const { setQuestionIndex, setName, setEmail, setPhone, setAnswers } =
  formSlice.actions;
export default formSlice.reducer;
