import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Share() {
  return (
    <div className="share-links">
      <a
        onClick={() =>
          window.open(
            "whatsapp://send?image=&text=Hello, I had a great experience at VIDRO, I recommend this place to visit"
          )
        }
      >
        <FaWhatsapp className="icon" />
      </a>
      <a
        onClick={() => {
          window.open("https://instagram.com/vidro.sa");
        }}
      >
        <FaInstagram className="icon" />
      </a>
      <a
        onClick={() => {
          window.open(
            "https://twitter.com/intent/tweet?text=Hello Guys, I spent a great time at #VIDRO, I recommend this place to visit"
          );
        }}
      >
        <FaXTwitter className="icon" />
      </a>
    </div>
  );
}

export default Share;
