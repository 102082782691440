import { Route, Routes } from "react-router-dom";
import Survey from "./pages/Survey";
import "./assets/app.css";
import "./assets/responsive.css";
import StoreWrapper from "./store/store";
import Main from "./pages/Main";
import ThankYou from "./pages/ThankYou";
import Information from "./pages/Information";

function App() {
  return (
    <StoreWrapper>
      <div className="App">
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/survey" element={<Survey />}></Route>
          <Route path="/information" element={<Information />}></Route>
          <Route path="/thankyou" element={<ThankYou />}></Route>
        </Routes>
      </div>
    </StoreWrapper>
  );
}

export default App;
