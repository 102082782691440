import i18next from "i18next";
import { useAppDispatch, useAppSelector } from "../store/store";
import { setAnswers } from "./redux/form-slice";

function Radio({ questionId, questionAnswers }) {
  const dispatch = useAppDispatch();
  const { answers } = useAppSelector((state) => state.form);

  function changeAnswer(answerId, answerValue) {
    var newAnswers = [...answers];

    var index = newAnswers.findIndex(
      (answer) => answer.question_id == questionId
    );

    if (index == -1) {
      newAnswers.push({
        question_id: questionId,
        answer_id: answerId,
        answer: answerValue,
      });
    } else {
      newAnswers[index] = {
        question_id: questionId,
        answer_id: answerId,
        answer: answerValue,
      };
    }

    dispatch(setAnswers(newAnswers));
  }

  return (
    <>
      {questionAnswers.map((answer, index) => {
        return (
          <div className="radio-answer" key={index}>
            <input
              type="radio"
              name={questionId}
              style={i18next.language == "ar" ? { marginLeft: "20px" } : {}}
              value={answer.answer}
              onChange={() => changeAnswer(answer.id, answer.answer)}
            />
            <label>{answer.answer}</label>
          </div>
        );
      })}
    </>
  );
}

export default Radio;
