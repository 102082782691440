import logo from "../assets/images/logo.png";
import splash from "../assets/images/splash.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useEffect } from "react";

function Main() {
  const { t } = useTranslation("global");
  const handleChangeLanguage = () => {
    if (i18next.language == "ar") {
      i18next.changeLanguage("en");
      localStorage.setItem("lang", "en");
    } else {
      i18next.changeLanguage("ar");
      localStorage.setItem("lang", "ar");
    }
  };

  useEffect(() => {
    localStorage.setItem("lang", "ar");
  }, []);

  return (
    <div className="splash-page">
      <div className="border-top">
        <span
          className="lang"
          onClick={() => {
            handleChangeLanguage();
          }}
        >
          {i18next.language}
        </span>
      </div>
      <img className="logo" src={logo} />
      <h2>{t("hello")}</h2>
      <a className="button" href="/survey">
        {t("ready")}
      </a>
      <img className="splash-image" src={splash} />
    </div>
  );
}

export default Main;
