import { configureStore } from "@reduxjs/toolkit";
import reducers from "./redux-index";
import { Provider } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";

const store = configureStore({
  reducer: reducers,
});

setupListeners(store.dispatch);

export { store };

const StoreWrapper = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default StoreWrapper;

export const RootState = store.getState();
export const AppDispatch = store.dispatch;
export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;
