import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import logo from "../assets/images/logo.png";
import splash from "../assets/images/splash.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import i18next from "i18next";

function ThankYou() {
  const { t } = useTranslation("global");

  const [loading, setLoading] = useState(true);
  const [recommendation, setRecommendation] = useState([]);
  const [recommendationCustomer, setRecommendationCustomer] = useState([]);

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem("lang"));

    axios
      .get("https://vidro.manialab.sa/api/get-recommendation")
      .then((response) => {
        if (response.data.success) {
          if (response.data.data == null) {
            // setRecommendation();
          } else {
            setRecommendation(response.data.data.answer);
            setRecommendationCustomer(
              response.data.data.submitted_form.customer.name
            );
          }
        } else {
          // setRecommendation();
        }
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <img src={logo} alt="logo" />
      </div>
    );
  } else {
    return (
      <div className="thankyou-page">
        <img src={logo} alt="logo" className="logo" />
        {/* <h1>{t("share")}</h1>
        <div className="share-links">
          <a
            onClick={() =>
              window.open(
                "whatsapp://send?image=&text=Hello%2C%20I%20had%20a%20great%20experience%20at%20VIDRO%2C%20I%20recommend%20this%20place%20to%20visit.%0AInstagram%3A%20https%3A%2F%2Fwww.instagram.com%2Fvidro.sa%2F%0ATwitter%3A%20https%3A%2F%2Ftwitter.com%2FVidro_sa%0AWhatsapp%3A%20https%3A%2F%2Fapi.whatsapp.com%2Fsend%2F%3Fphone%3D%252B966555351612"
              )
            }
          >
            <FaWhatsapp className="icon" />
          </a>
        </div> */}
        <div className="recommendations-container">
          <div className="thank">
            <div className="outline"></div>
            <span>{t("thankYou")}</span>
          </div>
          <div className="recommendations">
            <div className="name">{recommendationCustomer}</div>
            <div className="text">{recommendation}</div>
          </div>
        </div>
        <div className="footer">
          <span>{t("follow")}</span>
          <a href="https://www.instagram.com/vidro.sa/" target="_blank">
            <FaInstagram />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=%2B966555351612&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
          <a href="https://twitter.com/Vidro_sa" target="_blank">
            <FaXTwitter />
          </a>
        </div>
        <img className="splash-image" src={splash} />
        <div className="border-bottom"></div>
      </div>
    );
  }
}

export default ThankYou;
